<template>
  <div class="request-field">
    <template v-for="field in remappingDictionaryById">
      <single-field @change="$emit('change', $event)" :ref="field.id" :order="order" :field="field" :key="field.id" v-model="valueComputed" :isNumberOfPackages="isNumberOfPackages" />
    </template>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy'
import SingleField from 'theme/components/MyAccount/Rma/RequestFields/SingleField'

export default {
  name: 'FieldManager',
  props: {
    order: {
      type: Object,
      required: false,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    useRemap: {
      type: Object,
      default: () => {}
    },
    dictionary: {
      type: Array,
      default: () => []
    },
    isNumberOfPackages: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      requestSelections: {}
    }
  },
  computed: {
    valueComputed: {
      get () {
        return this.value || {}
      },
      set (newValue) {
        this.requestSelections = newValue
        this.$emit('input', newValue)
      }
    },
    remappingDictionaryById () {
      if (this.dictionary && this.dictionary[1] && this.dictionary[1].id === 9) {
        var options = this.dictionary[1].options
        var newOptions = options.splice(6, 1)
        options = newOptions
      }
      if (this.useRemap && Object.keys(this.useRemap).length) {
        const _this = this
        return sortBy(this.dictionary, [function (dict) {
          return _this.useRemap[dict.id]
        }])
      } else {
        return this.dictionary.filter(field => (field.active))
      }
    }
  },
  components: {
    SingleField
  }
}
</script>
<style scoped>
  .request-field {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
</style>
