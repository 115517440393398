<template>
  <div class="request-field">
    <label :label-for="`info-${field.id}`">{{$t(field.name)}}</label>
    <p v-if="isNumberOfPackages" class="my-returns-hint" v-html="$t('rma.returns.new.usageHint')" />
    <b-form-group>
      <component
      :data-vv-as="$t(field.name)"
      :is="fieldLayout"
      :type="field.type"
      :id="`info-${field.id}`"
      :name="`info-${field.id}`"
      :ref="`info-${field.id}`"
      v-model="valueComputed"
      :options="getOptionForSelect"
      :placeholder="$t(field.name)"
      selectClass="select-shadowed"
      @input="$emit('change', $event)"
      v-validate="fieldValidation"
      :reduce="isNumberOfPackages ? n => n.value : (value) => value"
      :validations="[
        {
          condition: errors.has(`info-${field.id}`),
          text: errors.first(`info-${field.id}`)
        }
      ]" />
    </b-form-group>
  </div>
</template>

<script>
import BaseInput from 'theme/components/Form/BaseInput'
import BaseSelect from 'theme/components/Form/BaseSelect'

export default {
  name: 'SingleField',
  inject: ['$validator'],
  props: {
    order: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      default: () => {}
    },
    field: {
      type: Object,
      default: () => {}
    },
    isNumberOfPackages: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      fieldVal: this.value,
      filedMap: {
        'select': 'BaseSelect',
        'text': 'BaseInput',
        componentKey: 0
      }
    }
  },
  methods: {
    resetFields (fieldsToReset) {
      if (fieldsToReset.includes(this.field.name)) {
        this.$delete(this.fieldVal, this.field.id)
      }
    }
  },
  computed: {
    valueComputed: {
      get () {
        if (this.value && this.value[this.field.id]) {
          return this.value[this.field.id]
        } else {
          return ''
        }
      },
      set (newValue) {
        if (newValue) {
          let val = newValue
          if (this.field.type === 'text') {
            val = newValue
          }
          this.$set(this.fieldVal, this.field.id, val)
        } else {
          this.$delete(this.fieldVal, this.field.id)
        }
        this.$emit('input', this.fieldVal)
      }
    },
    numberOfPackage () {
      let number = 0
      if (this.field.name === 'number of packages' && this.order && Object.keys(this.order).length) {
        // calc
        this.order.items.forEach((orderItem) => {
          number += ('qty_remaining_rma' in orderItem) ? orderItem.qty_remaining_rma : orderItem.qty_ordered
        })
      }
      return number
    },
    fieldLayout () {
      if (this.field.name === 'number of packages') {
        return 'BaseSelect'
      }
      if (this.filedMap[this.field.type]) {
        return this.filedMap[this.field.type]
      } else {
        return 'BaseSelect'
      }
    },
    getOptionForSelect () {
      if (this.field.name === 'number of packages') {
        let opts = []
        const _this = this
        let number = 0
        if (this.field.name === 'number of packages' && this.order && Object.keys(this.order).length) {
          this.order.items.forEach((orderItem) => {
            number += ('qty_remaining_rma' in orderItem) ? orderItem.qty_remaining_rma : orderItem.qty_ordered
          })
          if (number > 0) {
            for (let index = 1; index <= number; index++) {
              opts.push({
                field_name: _this.field.name,
                field_id: _this.field.id,
                disabled: false,
                label: index,
                value: index
              })
            }
          }
        }
        return opts
      } else {
        let opts = []
        const _this = this
        if (this.field && this.field.options) {
          const getOptionLabel = (option) => {
            if (option.store_labels.find(e => e.store_id === this.$store.state.storeConfig.config.id)) {
              let label = option.store_labels.find(e => e.store_id === this.$store.state.storeConfig.config.id).value
              return label
            } else {
              return option.storefront_label
            }
          }
          this.field.options.forEach((option) => {
            opts.push({
              field_name: _this.field.name,
              field_id: _this.field.id,
              disabled: !option.enabled,
              label: getOptionLabel(option),
              value: option.id
            })
          })
        }
        return opts
      }
    },
    fieldValidation () {
      let validationMap = {
        required: this.field.required
      }
      if (this.field.name === 'number of packages') {
        if (this.numberOfPackage) {
          // not === 0
          validationMap['required'] = true
          validationMap['numeric'] = true
          validationMap['min_value'] = 1
          validationMap['max_value'] = this.numberOfPackage
        }
      }
      return validationMap
    }
  },
  components: {
    BaseInput,
    BaseSelect
  }
}
</script>
