import Graphql from '@/services/Graphql'
import Magento from '@/services/Magento'
import Logger from '@/services/Logger'
import { graphqlManageError } from '@/helpers'
import Config from '@/config'
import get from 'lodash/get'

class Rma {
  list ({ token, storeViewCode, graphQuery = 'rmaList', variables = null }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      graphQuery,
      variables,
      false) // use cache
      .then(resp => {
        return resp && resp.data && resp.data.rmasExt ? resp.data.rmasExt.items : []
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  detail ({ token, storeViewCode, variables }) {
    return this.list({ token, storeViewCode, graphQuery: 'rmaDetail', variables })
      .then(resp => resp.length > 0 ? resp[0] : {})
  }

  getOrderAvailable ({ token, storeViewCode }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'rmaOrdersAvailable',
      null,
      false) // use cache
      .then(resp => {
        return resp && resp.data ? resp.data.rmaOrdersAvailable : {}
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  checkGuestOrder ({ token, storeViewCode, orderId, email }) {
    let data = {
      id: orderId,
      email: email
    }

    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'checkGuestOrder',
      data,
      false) // use cache
      .then(resp => { return resp })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  getOrderTemplate ({ token, storeViewCode, orderId, email }) {
    let data = {
      id: orderId
    }

    if (email) {
      data['email'] = email
    }

    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'rmaNewPrepare',
      data,
      false) // use cache
      .then(resp => {
        if (resp && resp.data && resp.data.orderTemplate) {
          resp.data.orderTemplate.items.map(item => {
            item.dictionarySelected = {
              qty_requested: null,
              condition_id: null,
              reason_id: null,
              resolution_id: null,
              resolution_sku: null
            }
            return item
          })
          return resp.data.orderTemplate
        } else if (resp.hasOwnProperty('errors')) {
          return resp
        } else {
          return {}
        }
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }
  createRMA ({ token, storeViewCode, inputRma, files }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'rmaCreate',
      {
        inputRma
      },
      false,
      files) // use cache
      .then(resp => {
        if (graphqlManageError(resp)) {
          // eslint-disable-next-line
          throw { body: { result: graphqlManageError(resp) } }
        }
        if (resp && resp.data && resp.data.createRmaExt) {
          return resp.data.createRmaExt.rma
        } else {
          return {}
        }
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  buildInputRma ({ items, order, storeId, isBoutique, reply = null, email }) {
    let tmp
    let type = 'regular'
    if (isBoutique) {
      type = 'boutique'
    }
    return {
      'guest_customer': {
        'order_id': order.order_id,
        'customer_email': email
      },
      'rma_orders': [
        {
          'order_id': order.order_id,
          'store_id': storeId,
          'type': type,
          'items': items.map(item => {
            tmp = {
              'order_item_id': item.order_item_id,
              'product_sku': item.item_sku,
              'qty_requested': item.dictionarySelected.qty_requested,
              'condition_id': parseInt(item.dictionarySelected.condition_id),
              'reason_id': parseInt(item.dictionarySelected.reason_id),
              'resolution_id': parseInt(item.dictionarySelected.resolution_id),
              'exchange_sku': item.dictionarySelected.resolution_sku ? item.dictionarySelected.resolution_sku : ''
            }
            return tmp
          })
        }
      ],
      'reply': reply
    }
  }

  // attach message and file
  attachment ({ rmaId, message, files, token, storeViewCode }) {
    let formData = new FormData()
    if (files && files.length) {
      files.forEach((f) => {
        formData.append('attachment[]', f)
      })
    }
    message && formData.append('message', message)
    rmaId && formData.append('rmaId', rmaId)
    return Magento.post(Config.API.rma.attachment,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Store': storeViewCode,
          'Content-Type': 'multipart/form-data'
        }
      })
      .catch(error => {
        throw error
      })
  }

  /**
   * return product in stock, start by variant sku
   */
  searchProductAvailable ({ simpleSku, storeViewCode, $store }) {
    return Graphql.genericCall(
      {},
      process.env.VUE_APP_MAGENTO_TOKEN,
      storeViewCode,
      'variantExt',
      {
        sku: simpleSku
      },
      false) // use cache
      .then(resp => {
        if (get(resp, 'data.getVariantExt.variant', false)) {
          let variants = resp.data.getVariantExt.variant.filter(v => v.stockStatus === 'IN STOCK' && v.sku !== simpleSku)
          variants = variants.map(variant => {
            return {
              label: variant.size,
              attribute: variant.size,
              sku: variant.sku
            }
          })
          return variants
        }
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }
}

const classSingleton = new Rma()

export default classSingleton
