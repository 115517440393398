<template>
  <div ref="rmaProducts">
    <div class="table-item" v-for="(item, index) in items" :key="index">
      <new-rma-product
      :item="item"
      :dictionary="dictionary"
      :mapAttachment="mapAttachment"
      :requestActiveField="requestActiveField"
      :order="order"
      ref="rmaProduct" />
    </div>
  </div>
</template>

<script>
import NewRmaProduct from './NewRmaProduct'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    dictionary: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      required: true
    },
    requestActiveField: {
      type: Object,
      default: () => {}
    },
    mapAttachment: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    items () {
      this.$nextTick(() => {
        this.$refs.rmaProduct.forEach(element => {
          element.toggleDetail(false)
        })
      })
    }
  },
  components: {
    NewRmaProduct
  }
}
</script>
