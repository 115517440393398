<template>
  <div class="rma-addfiles position-relative">
    <absolute-loader :showLoader="callLoader"></absolute-loader>
    <!-- attach file -->
    <div class="attach-file">
      <b-row class="upload-file-row">
        <b-col cols="12">
          <div class="rma-addfiles_files mb-4_1 form-group">
            <b-form-group id="groupUploadFile" class="upload-file-label mb-0">
              <label class="custom-file-upload">
                <input
                ref="form-file-img"
                id="form-file-img"
                type="file"
                class="d-none"
                :name="name"
                :required="required"
                @click="resetImageUploader"
                @change="processFile($event.target.files)"/>
                <span class="upload-intro">
                  <i class="icomoon-icon icon-plus"></i>
                  {{$t('rma.addFiles')}}
                </span>
              </label>
              <ul class="files-list">
                <li v-for="(file, index) in _files" :key="file.lastModified+index" class="filename-list">
                  <button @click="removeFile(index)" class="btn btn-none"><i class="icomoon-icon icon-x"></i></button>
                  <img :src="createUrlFromFile(file)">
                </li>
              </ul>
              <b-form-invalid-feedback :class="{ 'd-block': !validState }">
                <!-- This will only be shown if the preceeding input has an invalid state -->
                <div v-for="(validation, index) in validationsFiltered"
                  :key="index">
                  {{ validation.text }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <slot name="validation-feedback"></slot>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    rmaId: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: () => []
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    callLoader: false,
    base64: []
  }),
  computed: {
    _files: {
      get () {
        return this.files
      },
      set (newValue) {
        this.$emit('update:files', newValue)
      }
    },
    validState () {
      let state = null
      for (let validation of this.validations) {
        if (validation.condition) {
          state = false
          break
        }
      }
      return state
    },
    validationsFiltered () {
      return filter(this.validations, { condition: true })
    }
  },
  methods: {
    resetImageUploader () {
      this.$refs['form-file-img'].value = ''
    },
    createUrlFromFile (file) {
      this.createBase64(file)
      return URL.createObjectURL(file)
    },
    processFile (event) {
      const _this = this
      event.forEach(f => {
        _this._files.unshift(f)
      })
      this._files = this._files
    },
    createBase64 (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        let res = e.target.result
        this.base64.push(res)
      }
      reader.readAsDataURL(file)
    },
    removeFile (indexFile) {
      this._files.splice(indexFile, 1)
      this._files = this._files
    }
  },
  components: {
    AbsoluteLoader
  }
}
</script>
