<template>
  <div class="table-item" :key="name">
    <div class="table-tile"
    :class="{opaque: isReturnable}"
    :title="name"
    :id="item.item_id">
      <div class="table-tile__item">
        <div class="table-tile__item-block block-media">
          <b-form-checkbox
          class="open-rma"
          :name="`select-product-${item.item_id}`"
          :class="{ disabled: getItemQuantityRma <= 0 || isReturnable, hide: isReturnable }"
          v-model="showDetails"
          @change="toggleDetail"
          :disabled="isReturnable"
          />
          <img-product
          class="rma-product_img pr-2"
          :imgPath="image"
          :alt="name"
          :styleImage="{ maxWidth: '100%' }"
          />
        </div>
        <div class="table-tile__item-block block-name">
          <span class="title" v-html="name"></span>
          <div class="qty-already-reqs" v-show="itemAlreadyRequested">{{ $tc('rma.new.{tot}requestedPieces', itemAlreadyRequested, { tot: itemAlreadyRequested }) }}</div>
        </div>
        <div class="table-tile__item-block block-color" v-if="color">
          <span class="color-box" :style="swatchBackground"></span>
          <span class="color-label">{{color.value}}</span>
        </div>
        <div class="table-tile__item-block block-size" v-if="size">
          <div class="label">{{ $t('rma.new.product.size') }}</div>
          <span class="value">{{ size.value }}</span>
        </div>
        <div class="table-tile__item-block block-price">
          <span class="value">{{ item.price_incl_tax | price($store) }}</span>
        </div>
        <div class="table-tile__item-block block-qty">
          <div class="label">{{ $t('account.rma.Quantity') }}</div>
          <span class="value t-regular" v-html="getItemQuantityRma"></span>
        </div>
        <div class="table-tile__item-block block-subtotal block-latest">
          <span v-if="isReturnable">{{ $t('rma.new.product.nonRefundable') }}</span>
          <span v-else class="value f-bold">{{ subtotal | price($store) }}</span>
        </div>
      </div>
      <div class="add-more-info" ref="moreInfo" style="display: none;">
        <b-row>
          <b-col sm="6">
            <div class="add-more-info__input">
              <label class="label-qty" :label-for="'qty_' + sku">{{$t('account.rma.Quantity')}}</label>
              <b-form-group id="groupQty">
                <base-select
                :data-vv-as="$t('account.rma.Quantity')"
                selectClass="select-shadowed"
                :id="'qty_' + sku"
                :name="'qty_' + sku"
                v-model="orderQuantity"
                v-validate="'required'"
                :options="quantityOptions"
                :reduce="qty => qty.value"
                :placeholder="$t('account.rma.Quantity')"
                :validations="[
                  {
                    condition: errors.has('qty_' + sku),
                    text: errors.first('qty_' + sku)
                  }
                ]"
                ></base-select>
              </b-form-group>
            </div>
            <p></p>
            <field-manager ref="fieldManager" @change="onChange($event)" v-model="rmaItemsOptions" :order="order" :dictionary="mappedDictionary" :useRemap="remappingMap" />
            <div class="add-more-info__input">
              <upload-files
                :files.sync="files"
                :name="`upload-files-${name}`"
                v-model="files"
                ref="addFilesRma"
                data-vv-as="RMA"
                v-validate="{ required: this.isAttachmentRequired && !(this.files && this.files.length) }"
                :required="isAttachmentRequired"
                :validations="[
                  {
                    condition: errors.has(`upload-files-${name}`),
                    text: errors.first(`upload-files-${name}`)
                  }
                ]"
                >
              </upload-files>
            </div>
          </b-col>
          <b-col sm="6">
            <div class="add-more-info__input h-100">
              <add-message :reply.sync="message" />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import find from 'lodash/find'
import Config from 'config'
// import Rma from 'theme/services/Rma'
import ImgProduct from 'theme/components/Product/Image'
import FieldManager from 'theme/components/MyAccount/Rma/RequestFields/FieldManager'
import BaseSelect from 'theme/components/Form/BaseSelect'
import BaseCheckBox from 'theme/components/Form/BaseCheckBox'
import RmaProductsSizeSelector from './RmaProductsSizeSelector'
import AddMessage from 'theme/components/MyAccount/Rma/AddMessage'
import UploadFiles from 'theme/components/MyAccount/Rma/UploadFiles'
import BaseTextarea from 'theme/components/Form/BaseTextarea'
import { resizeService } from 'theme/helpers'

export default {
  name: 'newRmaProduct',
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      required: true
    },
    dictionary: {
      type: Array,
      default: () => []
    },
    requestActiveField: {
      type: Object,
      default: () => {}
    },
    mapAttachment: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      toggleDt: false,
      guest: false,
      rmaItemsOptions: {},
      qtyFields: [],
      messageChar: 400,
      confirmedQuantity: [],
      orderQuantity: '',
      reasonDetail: '',
      message: '',
      mappedDictionary: [],
      checkedProd: {},
      files: [],
      remappingMap: {
        // id field: position
        9: 0,
        3: 1,
        6: 2
      },
      isAttachmentRequired: false,
      nonReturnableItemsArray: []
    }
  },
  computed: {
    quantityOptions () {
      let n = 1
      let qtyOption = []
      let qty = ('qty_remaining_rma' in this.item) ? this.item.qty_remaining_rma : this.item.qty_ordered
      while (n <= qty) {
        qtyOption.push({
          label: n,
          value: n
        })
        n++
      }
      return qtyOption
    },
    showDetails: {
      get () {
        return this.toggleDt
      },
      set (newValue) {
        this.toggleDt = newValue
        this.$set(this.item, 'showDetails', newValue)
      }
    },
    image () {
      let imageUrl = this.item.extension_attributes.image_url
      if (imageUrl) {
        return resizeService({ url: imageUrl, color: 'f5f5f5' })
      } else {
        return ''
      }
    },
    name () {
      return this.item.name
    },
    sku () {
      return this.item.sku
    },
    color () {
      if (this.item && this.item.extension_attributes && this.item.extension_attributes.selected_options) {
        return find(this.item.extension_attributes.selected_options, { 'attribute_code': 'color_detail' })
      }
      return false
    },
    size () {
      if (this.item && this.item.extension_attributes && this.item.extension_attributes.selected_options) {
        return find(this.item.extension_attributes.selected_options, { 'attribute_code': 'size' })
      }
      return false
    },
    subtotal () {
      let subtotal = 0
      if (this.item) {
        let item = this.item
        let productPrice, price, discount, singleItemDiscount, qtyOrder
        if (item.discount_amount > 0) {
          discount = item.discount_amount
          price = item.price_incl_tax
          qtyOrder = item.qty_ordered
          // qty = item.qty_remaining_rma
          singleItemDiscount = (discount / qtyOrder)
          productPrice = Math.abs(Math.round(((price - singleItemDiscount)) * 100) / 100)
          // subtotal = productPrice * (item.qty_remaining_rma ? item.qty_remaining_rma : item.qty_ordered)
        } else {
          productPrice = Config.Theme.priceIncludeTax ? item.base_price_incl_tax : item.base_price
        }
        subtotal = productPrice * (item.qty_remaining_rma ? item.qty_remaining_rma : item.qty_ordered)
      }
      return subtotal
    },
    swatchBackground () {
      if (this.color) {
        return this.color.color_image && this.color.color_image.includes('#')
          ? `background: #${this.color.color_image.split('#')[1]}`
          : `background: center / contain url(${this.color.color_image})`
      }
      return false
    },
    charCount () {
      if (this.message) {
        if (this.message.length >= 400) {
          return 0
        } else {
          return this.messageChar - this.message.length
        }
      }
      return this.messageChar
    },
    getItemQuantityRma () {
      if (('qty_remaining_rma' in this.item)) {
        if (this.item.qty_remaining_rma <= 0) {
          return 0
        }
        return this.item.qty_remaining_rma
      }
      return this.item.qty_ordered
    },
    itemAlreadyRequested () {
      return this.item.qty_ordered - this.getItemQuantityRma
    },
    isReturnable () {
      console.log(this.item.sku)
      console.log(this.nonReturnableItemsArray)
      return this.$store.state.rma.nonReturnable.includes(this.item.sku)
    }
  },
  methods: {
    clone (Obj) {
      let buf // the cloned object
      if (Obj instanceof Array) {
        buf = [] // create an empty array
        var i = Obj.length
        while (i--) {
          buf[i] = this.clone(Obj[i]) // recursively clone the elements
        }
        return buf
      } else if (Obj instanceof Object) {
        buf = {} // create an empty object
        for (const k in Obj) {
          if (Obj.hasOwnProperty(k)) { // filter out another array's index
            buf[k] = this.clone(Obj[k]) // recursively clone the value
          }
        }
        return buf
      } else {
        return Obj
      }
    },
    resetFields (reasonSelected) {
      let fieldsToReset = []
      switch (reasonSelected.field_name) {
        case 'Reason':
          fieldsToReset = ['Resolution', 'Reason detail']
          break
        case 'Resolution':
          fieldsToReset = ['Reason detail']
          break
        case 'allFields':
          fieldsToReset = ['Resolution', 'Reason detail', 'Reason']
          this.orderQuantity = ''
          this.message = ''
          this.files.splice(0)
          break
      }
      let rmaFieldsComponents = this.$refs.fieldManager.$refs
      Object.keys(rmaFieldsComponents).forEach(key => {
        rmaFieldsComponents[key][0].resetFields(fieldsToReset)
      })
    },
    onChange (reasonSelected) {
      this.resetFields(reasonSelected)
      var relations = []
      var reason = this.dictionary.find(el => el.options[0] && el.options[0].relation)
      if (reason && reason.options) {
        reason.options.forEach(opt => {
          relations.push({
            id: opt.id,
            label: opt.storefront_label,
            relation: opt.relation
          })
        })
      }
      let relResolution
      let relReasonDetail
      let mappedResolution = []
      let mappedReasonDetail = []
      if (reasonSelected.field_name === 'Reason') {
        let resolution = this.dictionary.find(el => el.name === 'Resolution').options
        let reasonDetail = this.dictionary.find(el => el.name === 'Reason detail').options
        let resolutionIdx = this.dictionary.findIndex(el => el.name === 'Resolution')
        let reasondetailIdx = this.dictionary.findIndex(el => el.name === 'Reason detail')
        relResolution = relations.find(el => el.id === reasonSelected.value).relation.child1_option_ids
        relResolution.forEach(rel => {
          /* spread new filters to preserve previous ones */
          mappedResolution = [...mappedResolution, ...resolution.filter(opt => parseInt(rel) === parseInt(opt.id))]
        })
        relReasonDetail = relations.find(el => el.id === reasonSelected.value).relation.child2_option_ids
        relReasonDetail.forEach(rel => {
          /* spread new filters to preserve previous ones */
          mappedReasonDetail = [...mappedReasonDetail, ...reasonDetail.filter(opt => parseInt(rel) === parseInt(opt.id))]
        })
        this.mappedDictionary[resolutionIdx].options = mappedResolution
        this.mappedDictionary[reasondetailIdx].options = mappedReasonDetail
      }

      // console.log(mappedReasonDetail)
    },
    isAttachmentRequiredByMap () {
      // use map (mapAttachment) to set as required input-file
      let isRequired = false
      let rmaItemObj = Object.keys(this.rmaItemsOptions)
      let requestActiveField = Object.keys(this.requestActiveField) || []
      rmaItemObj.concat(requestActiveField)
      if (rmaItemObj.length) {
        for (let i = 0; i < rmaItemObj.length; i++) {
          // find option true
          if (this.rmaItemsOptions[rmaItemObj[i]] && this.mapAttachment[this.rmaItemsOptions[rmaItemObj[i]].value]) {
            isRequired = true
            break
          }
        }
      }
      this.isAttachmentRequired = isRequired
      return isRequired
    },
    isValid () {
      return this.$validator.validateAll()
    },
    optionAvailable (maxQty) {
      return Array.from({ length: maxQty }, (v, k) => k + 1)
    },
    getOptionForSelect (field) {
      let opts = []
      if (field && field.options) {
        field.options.forEach(e => {
          opts.push({
            field_name: field.name,
            field_id: field.id,
            disabled: !e.enabled,
            label: this.getOptionLabel(e),
            value: e.id
          })
        })
      }
      return opts
    },
    getOptionLabel (el) {
      if (el.store_labels.find(e => e.store_id === this.$store.state.storeConfig.config.id)) {
        let label = el.store_labels.find(e => e.store_id === this.$store.state.storeConfig.config.id).value
        return label
      } else {
        return el.storefront_label
      }
    },
    toggleDetail (forceToggle = null) {
      this.resetFields({ field_name: 'allFields' })
      const _this = this
      if (forceToggle !== null) {
        this.toggleDt = forceToggle
        this.showDetails = forceToggle
      } else {
        this.toggleDt = !this.showDetails
      }
      $(_this.$refs.moreInfo).toggle(_this.toggleDt)
    },
    isGuest () {
      const _this = this
      const id = this.$route.params.id
      const email = this.$route.params.email
      if (id && email) {
        _this.guest = true
      }
    },
    setReturnData () {
      let uploadAttach = []
      if (this.files && this.files.length) {
        this.files.forEach(file => {
          uploadAttach.push({
            name: file.name,
            content: this.$refs.addFilesRma.createUrlFromFile(file)
          })
        })
      }
      let request = {
        id: this.item.item_id,
        itemData: this.item,
        // files: this.files,
        isAttachmentRequired: this.isAttachmentRequired,
        upload_attachments: uploadAttach,
        qty: this.orderQuantity,
        custom_fields: this.rmaItemsOptions,
        message: this.message
      }
      return request
    },
    getStoredRequest () {
      const _this = this
      return this.$store.dispatch('rma/getGuestStoredRequest', { orderId: this.item.order_id, productId: this.item.item_id })
        .then((resp) => {
          if (resp) {
            // open panel
            _this.toggleDetail()
            // set qty
            _this.orderQuantity = resp.qty
            // set custom fields
            if (resp.custom_fields) {
              for (let i in resp.custom_fields) {
                _this.$set(_this.rmaItemsOptions, i, resp.custom_fields[i])
              }
            }
            // set message
            _this.message = resp.message
            // set attachments
            _this.files = resp.files
          }
        })
    }
  },
  mounted () {
    this.isGuest()
    this.getStoredRequest()
    this.mappedDictionary = this.clone(this.dictionary)
    this.nonReturnableItemsArray = this.$store.dispatch('rma/nonReturnableItems', { storeViewCode: this.$store.state.ui.storeViewCode })
  },
  watch: {
    requestActiveField: {
      handler: function () {
        this.isAttachmentRequiredByMap()
      },
      deep: true,
      immediate: false
    },
    rmaItemsOptions: {
      handler: function () {
        this.isAttachmentRequiredByMap()
      },
      deep: true,
      immediate: false
    }
  },
  components: {
    ImgProduct,
    BaseSelect,
    RmaProductsSizeSelector,
    FieldManager,
    AddMessage,
    BaseTextarea,
    BaseCheckBox,
    UploadFiles
  }
}
</script>

<style>
.label-qty {
  font-weight: bold;
  padding-left: 0px !important;
  font-size: 14px;
  text-transform: uppercase;
}
</style>
